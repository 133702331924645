import { Outlet } from "react-router-dom";
// @mui
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
// images
import loginCover from "../../assets/journals.jpg";

export default function OnlyFormLayout() {
  const classes = { paper: "paper", root: "root fullscreen" };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        // backgroundImage: {
        //   xs: "url(/public/login_sample.jpeg)",
        //   md: "url(/public/login_sample.jpeg)",
        // },
        backgroundSize: "cover",
        backgroundPosition: "center left",
      }}
    >
      <Grid
        container
        spacing={0}
        className={classes.root}
        direction="row"
        margin="0 auto"
        alignItems="center"
        sx={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            minHeight: "100vh",
            display: {
              xs: "none",
              md: "flex",
            },
            justifyContent: {
              xs: "center",
            },
            padding: {
              xs: "50px 0",
              md: "0",
            },
            boxShadow: "0px 0px 20px rgba(4, 73, 144, 0.52)",
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: {
                xs: "70%",
                md: "100%",
              },
              objectFit: "cover",
              objectPosition: "30% center",
              opacity: 0.9,
            }}
            src={loginCover}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}
