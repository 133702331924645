import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import AuthGuard from 'src/components/guards/AuthGuard';
import GuestGuard from 'src/components/guards/GuestGuard';
// layouts
import AuthLayout from '../layouts/auth';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: '/auth',
            element: 
                <GuestGuard>
                    <AuthLayout />
                </GuestGuard>,
            children: [
                { path: 'login', element: <LoginPage /> },
            ],
        },
        {
            path: '/generator',
            element:
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>,
            children: [
                { path: '', element: <GeneratorPage /> },
            ],
        },
        {
            path: '/dashboard',
            element:
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>,
            children: [
                { element: <Navigate to="/dashboard/affiliates" replace />, index: true },
                { path: 'journals', element: <JournalsPage /> },
                { path: 'affiliates', element: <AffiliatesPage /> },
                {
                    path: 'user',
                    children: [
                        { element: <Navigate to="/dashboard/user/four" replace />, index: true },
                        { path: 'four', element: <PageFour /> },
                        { path: 'five', element: <PageFive /> },
                        { path: 'six', element: <PageSix /> },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '/', element: <Navigate to="/generator" replace /> },
    ]);
}


// Dashboard
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
const GeneratorPage = Loadable(lazy(() => import('../pages/GeneratorPage')));
const AffiliatesPage = Loadable(lazy(() => import('../pages/AffiliatesPage')));
const JournalsPage = Loadable(lazy(() => import('../pages/JournalsPage')));
const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
