import { create } from 'zustand'
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, getTokenData } from '../utils/jwt';

const token = localStorage.getItem('accessToken');

const initialState = {
    isAuthenticated: isValidToken(token),
    user: isValidToken(token) ? getTokenData(token) : {},
    method: 'jwt',
};

const login = async (email, password) => {
    const res = await axios.post('/auth/login', {
        email,
        password
    });

    const { user, accessToken } = res.data;
    setSession(accessToken);

    return { user, accessToken };
};

const logout = async () => {
    const response = await axios.post('/auth/logout');
    setSession(null);

    return response;
}

const profile = async () => {
    const res = await axios.post('/auth/profile');

    const { user } = res.data;

    return { user };
}

const useAuth = create((set) => ({
    ...initialState,
    profile: async () => {
        const { user } = await profile();
        return { user };
    },
    login: async (email, password) => {
        const { user, accessToken } = await login(email, password);
        set({
            isAuthenticated: isValidToken(accessToken),
            user
        });
    },
    logout: async () => {
        await logout();
        set({
            isAuthenticated: false,
            user: null
        });
    }
}))

export default useAuth;