// theme
import palette from "../theme/palette";

// ----------------------------------------------------------------------

export const colorPresets = [
  // JATS PRESET
  {
    name: "jats",
    lighter: "#df7c82",
    light: "#d45059",
    main: "#bf2f38",
    dark: "#99262d",
    darker: "#731c22",
    contrastText: "#fff",
  },
  // DEFAULT
  {
    name: "default",
    ...palette.light.primary,
  },
];

export const jatsPreset = colorPresets[0];
export const defaultPreset = colorPresets[1];

export default function getColorPresets(presetsKey) {
  return {
    jats: jatsPreset,
    default: defaultPreset,
  }[presetsKey];
}
