import axios from "axios";

// ----------------------------------------------------------------------

const saveToken = (accessToken) => {
    if (accessToken) {
        localStorage.setItem("accessToken", accessToken);

        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        delete axios.defaults.headers.Authorization;
    }
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("accessToken")
            ? `Bearer ${localStorage.getItem("accessToken")}`
            : null,
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/login" && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                saveToken();

                window.location.href = "/auth/login";
                // TODO: refresh token and retry request
                // try {
                //   const response = await axiosInstance.post("/auth/refresh", {
                //     refreshToken: localStorage.getItem("refreshToken"),
                //   });
                //   console.log("access token from axiosInstance");
                //   const { accessToken } = response.data;
                //   saveToken(accessToken);
                //   return axiosInstance(originalConfig);
                // } catch (_error) {
                //   console.log("error from axiosInstance", _error);
                //   return Promise.reject(
                //     err.response || {
                //       status: 401,
                //       data: {
                //         status: 'fail',
                //         message: 'Unauthorized'
                //       }
                //     }
                //   );
                // }
            }
        }

        return Promise.reject(
            err.response || {
                status: 500,
                data: {
                    status: "fail",
                    message: "Something went wrong",
                },
            }
        );
    }
);

export default axiosInstance;
