import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

import edraLogo from "../assets/edra_alt.png";

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box
      sx={{
        width: 40,
        height: 40,
        alignItems: "center",
        display: "flex",
        ...sx,
      }}
    >
      <Box
        component="img"
        sx={{
          maxWidth: {
            xs: "100%",
          },
          objectFit: "contain",
          objectPosition: "center center",
          opacity: 1,
        }}
        src={edraLogo}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
