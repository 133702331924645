import { create } from "zustand";

const initialState = {
  aggregator: {
    id: "",
    title: "",
    logoUrl: ""
  },
  journal: {
    id: "",
    journalTitle: "",
    publisherName: "",
    publisherLocation: "",
    issnPpub: "",
    issnEpub: ""
  },
  activeStep: 0,
  isStepperCompleted: false,
  isPrefilledJournalFields: false // Indicates if form values has been pre-filled
};

const useGenerator = create((set) => ({
  ...initialState,
  setAggregator: (aggregator) => set({ aggregator: aggregator }),
  setJournal: (journal) => set({ journal: journal }),
  setActiveStep: (step) => set({ activeStep: step }),
  completeStepper: () => set({ isStepperCompleted: true }),
  setIsPrefilledJournalFields: () => set({ isPrefilledJournalFields: true }),
}));

export default useGenerator;
