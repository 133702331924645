// components
import SvgIconStyle from "../../../components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  banking: getIcon("ic_banking"),
  kanban: getIcon("ic_kanban"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "General",
    items: [
      { title: "Generator", path: "/generator", icon: ICONS.dashboard },
      { title: "Journals", path: "/dashboard/journals", icon: ICONS.kanban },
      {
        title: "Affiliates",
        path: "/dashboard/affiliates",
        icon: ICONS.banking,
      },
    ],
  },
];

export default sidebarConfig;
