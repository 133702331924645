import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// contexts
import useAuth from '../../contexts/AuthContext';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if ( ! isAuthenticated ) {
    return <Navigate to="/auth/login" />;
  }

  return <>{children}</>;
}