import jwtDecode from 'jwt-decode';
// utils
import axios from './axios';

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const getTokenData = (accessToken) => {
    if (!accessToken) {
        return {};
    }
    return jwtDecode(accessToken);
};

const setSession = async (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        // delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.Authorization;
    }
};

export { isValidToken, setSession, getTokenData };