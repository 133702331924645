// @mui
import { Box, Typography } from '@mui/material';
// contexts
import useGenerator from '../../contexts/GeneratorContext';

export default function SettingStatus() {
  const aggregator = useGenerator((state) => state.aggregator);
  const journal = useGenerator((state) => state.journal);

  return (
    <Box>
      <Typography>
        Aggregatore: {aggregator?.title}
      </Typography>
      <Typography>
        Rivista: {journal?.journalTitle}
      </Typography>
    </Box>
  );
}
